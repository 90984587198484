import request from '@/utils/request';

// POST /home/homepartner/delete
//   删除合作公司
//
// GET /home/homepartner/info/{id}
// 根据Id获得合作公司信息
//
// GET /home/homepartner/list
// 获得合作公司列表
//
// POST /home/homepartner/save
// 保存合作公司
//
// POST /home/homepartner/update
// 修改合作公司

export function homepartnerDel(data) {
  return request({
    url: '/home/homepartner/delete',
    method: 'post',
    data,
  });
}

export function homepartnerList(query) {
  return request({
    url: '/home/homepartner/list',
    method: 'get',
    params: query
  });
}

export function homepartnerSave(data) {
  return request({
    url: '/home/homepartner/save',
    method: 'post',
    data
  });
}

export function homepartnerUpdate(data) {
  return request({
    url: '/home/homepartner/update',
    method: 'post',
    data
  });
}

export function homepartnerInfo(id) {
  return request({
    url: `/home/homepartner/info/${id}`,
    method: 'get',
  });
}

// POST /home/homebanner/delete
//   删除首页banner
//
// GET /home/homebanner/info/{id}
// 根据Id获得首页banner信息
//
// GET /home/homebanner/list
// 获得首页banner列表
//
// POST /home/homebanner/save
// 保存首页banner
//
// POST /home/homebanner/update
// 修改首页banner

export function homebannerDel(data) {
  return request({
    url: '/home/homebanner/delete',
    method: 'post',
    data,
  });
}

export function homebannerList(query) {
  return request({
    url: '/home/homebanner/list',
    method: 'get',
    params: query
  });
}

export function homebannerSave(data) {
  return request({
    url: '/home/homebanner/save',
    method: 'post',
    data
  });
}

export function homebannerUpdate(data) {
  return request({
    url: '/home/homebanner/update',
    method: 'post',
    data
  });
}

export function homebannerInfo(id) {
  return request({
    url: `/home/homebanner/info/${id}`,
    method: 'get',
  });
}

// POST /home/homeservice/delete
//   删除服务介绍
//
// GET /home/homeservice/info/{id}
// 根据Id获得服务介绍信息
//
// GET /home/homeservice/list
// 获得服务介绍列表
//
// POST /home/homeservice/save
// 保存服务介绍
//
// POST /home/homeservice/update
// 修改服务介绍

export function homeserviceDel(data) {
  return request({
    url: '/home/homeservice/delete',
    method: 'post',
    data,
  });
}

export function homeserviceList(query) {
  return request({
    url: '/home/homeservice/list',
    method: 'get',
    params: query
  });
}

export function homeserviceSave(data) {
  return request({
    url: '/home/homeservice/save',
    method: 'post',
    data
  });
}

export function homeserviceUpdate(data) {
  return request({
    url: '/home/homeservice/update',
    method: 'post',
    data
  });
}

export function homeserviceInfo(id) {
  return request({
    url: `/home/homeservice/info/${id}`,
    method: 'get',
  });
}

// POST /home/homenews/delete
//   删除行业资讯
//
// GET /home/homenews/info/{id}
// 根据Id获得行业资讯信息
//
// GET /home/homenews/list
// 获得行业资讯列表
//
// POST /home/homenews/save
// 保存行业资讯
//
// POST /home/homenews/update
// 修改行业资讯

export function homenewsDel(data) {
  return request({
    url: '/home/homenews/delete',
    method: 'post',
    data,
  });
}

export function homenewsList(query) {
  return request({
    url: '/home/homenews/list',
    method: 'get',
    params: query
  });
}

export function homenewsSave(data) {
  return request({
    url: '/home/homenews/save',
    method: 'post',
    data
  });
}

export function homenewsUpdate(data) {
  return request({
    url: '/home/homenews/update',
    method: 'post',
    data
  });
}

export function homenewsInfo(id) {
  return request({
    url: `/home/homenews/info/${id}`,
    method: 'get',
  });
}

//
// GET /home/homewebsiteinfo/info
// 根据Id获得网站信息信息
//
// POST /home/homewebsiteinfo/save
// 保存网站信息
//
// POST /home/homewebsiteinfo/update
// 修改网站信息

export function homewebsiteInfoUpdate(data) {
  return request({
    url: '/home/homewebsiteinfo/update',
    method: 'post',
    data
  });
}

export function homewebsiteInfoSave(data) {
  return request({
    url: '/home/homewebsiteinfo/save',
    method: 'post',
    data
  });
}

export function homewebsiteInfo() {
  return request({
    url: '/home/homewebsiteinfo/info',
    method: 'get',
  });
}

// GET /home/homeinfo/info
// 根据Id获得公司介绍信息
//
// POST /home/homeinfo/save
// 保存公司介绍

export function homeinfoInfo() {
  return request({
    url: '/home/homeinfo/info',
    method: 'get',
  });
}

export function homeinfoSave(data) {
  return request({
    url: '/home/homeinfo/save',
    method: 'post',
    data
  });
}
