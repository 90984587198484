<template>
  <div class="app-container companyInfo-page">
    <!-- 添加或修改会员对话框 -->
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="概述" prop="phone">
        <el-input rows="3" type="textarea" v-model="form.resume" placeholder="概述" />
      </el-form-item>

      <template v-for="(item, index) in form.detail">
        <el-form-item :label="'标题'+`${index + 1}`" prop="phone">
          <el-input v-model="form.detail[index].topic" placeholder="标题" />
        </el-form-item>

        <el-form-item :label="'内容'+`${index + 1}`" prop="phone">
          <el-input rows="3" type="textarea" v-model="form.detail[index].content" placeholder="内容" />
        </el-form-item>
      </template>

      <el-form-item label="" prop="">
        <el-button type="primary" @click="submitForm">更 新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { homeinfoSave, homeinfoInfo } from '@/api/official';

import { uploadFile, } from '@/api/tool/file';

export default {
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      // 表单参数
      form: {
        resume: '',
        detail: [
          {
            topic: '',
            content: ''
          },
          {
            topic: '',
            content: ''
          },
          {
            topic: '',
            content: ''
          }]
      },
      // 表单校验
      rules: {}
    };
  },
  created() {
    this.handleUpdate();
  },
  methods: {
    onEditorReady(editor) { // 准备编辑器

    },
    onEditorBlur() {
    }, // 失去焦点事件
    onEditorFocus() {
    }, // 获得焦点事件
    onEditorChange() {
    }, // 内容改变事件

    fileUpload(file, fileList) {
      console.log(file, fileList);
      const data = new FormData();
      data.append('file', file.raw);
      uploadFile(data)
        .then((res) => {
          // console.log(res);
          this.form.logo = res.url;
        });
    },
    handleAvatarSuccess(res, file) {
      this.logo = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },

    // 表单重置
    reset() {
      this.form = {
        title: '',
        logo: '',
        content: '',
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      homeinfoInfo()
        .then((response) => {
          this.form = response.data;
          this.open = true;
          this.title = '修改';
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          homeinfoSave(this.form)
            .then((response) => {
              this.msgSuccess('提交成功');
              this.open = false;
              this.handleUpdate();
              // this.getList();
            });
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
.companyInfo-page {
  width: 850px;

  .dialog-footer button {
    margin-left: 50px;
  }

  .logo-info {
    width: 100px;
  }

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

}

</style>
